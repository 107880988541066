import * as watermark from "watermarkjs";

const createWatermark = async image => {
  let imageFile;
  if (typeof image === "string") {
    imageFile = await createFile(image);
  } else {
    imageFile = image;
  }
  let watermarkedFile;
  const options = {
    init(img) {
      img.crossOrigin = `anonymous`;
    },
  };
  await watermark([imageFile, require(`@/asset/carbuyer_logo.png`)], options)
    .image(function(target, logo) {
      const context = target.getContext("2d");
      context.save();

      context.globalAlpha = 1.0;
      const lWidth = target.width * 0.125;
      const lHeight = (lWidth / logo.width) * logo.height;
      context.drawImage(
        logo,
        target.width * 0.77,
        target.height * 0.85,
        lWidth,
        lHeight
      );

      context.restore();

      return target;
    })
    .then(img => {
      const arr = img.src.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      watermarkedFile = new File([u8arr], imageFile.name, { type: mime });
    })
    .catch(e => {
      console.log(e);
      watermarkedFile = "error";
    });
  return watermarkedFile;
};

const createFile = async url => {
  const filePath = new URL(url).pathname.split("/");
  const filePathName = filePath[filePath.length - 1];
  const response = await fetch(url, { mode: "cors", cache: "no-cache" });
  const data = await response.blob();
  const fileType = response.headers.get("content-type");
  return new File([data], filePathName, { type: fileType });
};

export default createWatermark;
