import getAxios from "services/axios-get";

const createSignedurl = async (image, token) => {
  const split = image.split("/");
  const origName = split[split.length - 1];
  const wmFileName = `${origName.split("_org.")[0]}_wm.png`;
  let responce;
  await getAxios(
    `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/create-signed-url`,
    {
      fileName: wmFileName,
    },
    token
  )
    .then(res => {
      responce = res;
    })
    .catch(err => {
      console.log(err);
      responce = "error";
    });
  return responce;
};
export default createSignedurl;
