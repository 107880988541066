const uploadSignedImage = async (file, url) => {
  await fetch(url, {
    method: "PUT",
    body: file,
  })
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
      return "error";
    });
};

export default uploadSignedImage;
