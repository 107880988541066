import getAxios from "services/axios-get";
import putAxios from "services/axios-put";

export default {
  props: {
    modelId: {
      type: String,
    },
    fuelTypeName: {
      type: String,
    },
  },
  watch: {},
  data() {
    return {
      driveTypeList: [
        "All-wheel drive",
        "Front-wheel drive",
        "Rear-wheel drive",
      ],
      numberList: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "unknown",
      ],
      automaticParkingList: [
        "Autonomous",
        "Sensors & Cameras",
        "Sensors",
        "Unknown",
      ],
      parkingCameraList: [
        "360 Camera",
        "Front and Rear Camera",
        "Rear Camera",
        "Yes",
        "No",
        "Unknown",
      ],
      driverList: ["Driver only", "Front only", "Yes", "No", "Unknown"],
      wiredList: ["Wired", "Wireless", "No", "Unknown"],
      appPlatfromList: ["Apple only", "Android only", "Yes", "No", "Unknown"],
      yesNoUnknownList: ["Yes", "No", "Unknown"],
      lightList: ["LED", "Halogen", "Yes", "Unknown"],
      fabricList: ["Fabric", "Leather", "Pleather", "Unknown"],
      tab: 1,
      fuelTypeTabs: [
        { id: 0, name: "Petrol(ICE)" },
        { id: 1, name: "Hybrid" },
        { id: 2, name: "Electric" },
      ],
      alertMsg: null,
      type: null,
      showDialog: false,
      carModelExtras: {
        id: "",
        wheelBase: "",
        kerbWeight: "",
        vehicleType: "",
        fuelTankCapacity: "",
        minTurningRadius: "",
        bootCargoCapacity: "",
        dimensionsLxWxH: "",
        remarks: "",
        tractionControl: "",
        numberOfAirbags: "",
        cruiseControl: "",
        autoHeadlights: "",
        paddleShifters: "",
        navigationSystem: "",
        bluetoothInterface: "",
        rainSensingWipers: "",
        keylessEngineStart: "",
        electricParkBrakeButton: "",
        multiFunctionSteeringWheel: "",
        electricalRetractableSideMirrors: "",
        rims: "",
        headlight: "",
        frontFogLamps: "",
        daytimeRunningLights: "",
        sunroofMoonroofPanoramicRoof: "",
        rearAircon: "",
        leatherSeats: "",
        reverseCamera: "",
        multizoneAircon: "",
        driversMemorySeat: "",
        knockdownRearSeats: "",
        driversElectricSeat: "",
        leatherSteeringWheel: "",
        passengersMemorySeat: "",
        passengersElectricSeat: "",
        smartKey: "",
        electricTailgate: "",
        remoteBootRelease: "",
        additionalFeatures: "",
        brakesRear: "",
        brakesFront: "",
        fuelType: "",
        engineType: "",
        engineCapacity: "",
        suspensionRear: "",
        suspensionFront: "",
        power: "",
        torque: "",
        topSpeed: "",
        acceleration: "",
        co2Emission: "",
        maxPowerOutput: "",
        fuelConsumption: "",
        driveType: "",
        transmission: "",
        description: "",
        batteryCapacity: "",
        energyConsumption: "",
        driveRange: "",
        batteryType: "",
        powerMotor: "",
        powerCombine: "",
        lanekeepAssist: "",
        bodyControlModule: "",
        fwdCollisionAssist: "",
        hillStartAssist: "",
        hillDescentAssist: "",
        automaticParkingAssist: "",
        parkingCameras: "",
        autoHold: "",
        rearCrossTrafficAlert: "",
        frontCrossTrafficAlert: "",
        driverAlertnessSystem: "",
        electronicStabilityProgramme: "",
        laneDepartureWarning: "",
        appleCarPlay: "",
        androidAuto: "",
        digitalDriverDisplay: "",
        infotainment: "",
        voiceAssistantCommand: "",
        connectedSmartApp: "",
        handsFreeBootRelease: "",
        remoteEngineStart: "",
        autoDimmingMirrors: "",
        poweredWindows: "",
        adaptiveHeadLight: "",
        tailLight: "",
        automaticHighBeam: "",
        ioniser: "",
        ambientLighting: "",
        seatType: "",
        steeringWheel: "",
        specialSoundSystem: "",
        sos: "",
        runFlatTyres: "",
        spareWheel: "",
      },
    };
  },
  methods: {
    showExtrasDialog(modelId) {
      var self = this;
      const ftn = self.fuelTypeName;

      if (ftn == "Petrol-Electric" || ftn == "Diesel-Electric") {
        this.tab = 1;
      } else if (ftn.includes("Electric")) {
        this.tab = 2;
      } else {
        this.tab = 0;
      }

      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/carModelExtras?carModelId=${modelId}`,
        {},
        self.token
      )
        .then(res => {
          self.carModelExtras = res.data.data;
          self.extrasDialog = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    doUpdExtras(carModelExtras) {
      console.log(carModelExtras.id);
      var self = this;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/carModelExtras`,
        carModelExtras,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.showDialog = false;
            // self.showAlert("success", "Extras Saved Successfully!");
            self.$emit("upd");
          }
        })
        .catch(e => {
          self.showAlert("error", "Error updating!");
          self.$emit("error", e);
          console.error(e);
        });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
