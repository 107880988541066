const MAX_WIDTH = 1280;
const MAX_HEIGHT = 720;
const MIME_TYPE = "image/jpeg";
const QUALITY = 1;

const optimizeImageSize = file => {
  return new Promise((res, rej) => {
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      rej(new Error("Cannot load image"));
    };
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        blob => {
          const newFile = new File([blob], file.name, {
            type: file.type,
          });
          res({ file: newFile, url: URL.createObjectURL(newFile) });
        },
        MIME_TYPE,
        QUALITY
      );
    };
  });
};

function calculateSize(img, maxWidth, maxHeight) {
  let width = img.width;
  let height = img.height;
  if (width > maxWidth || height > maxHeight) {
    const resizedWidth = Math.round((width * maxHeight) / height);
    if (resizedWidth > maxWidth) {
      height = Math.round((maxHeight * maxWidth) / resizedWidth);
      width = maxWidth;
    } else {
      width = resizedWidth;
      height = maxHeight;
    }
  }
  return [width, height];
}

export default optimizeImageSize;
