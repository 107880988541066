<template>
  <div v-cloak @drop.prevent="dropImgs" @dragover.prevent>
    <v-dialog
      :value="uploadProgress.currentImage > 0"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          Uploading Image {{ uploadProgress.currentImage }}/{{ imgs.length }}
        </v-card-title>
        <v-progress-linear
          :value="uploadProgress.currentPercentage"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>{{ value }}%</strong>
          </template>
        </v-progress-linear>
      </v-card>
    </v-dialog>
    <v-file-input
      @change="addImgs"
      v-model="imgInput"
      clearable
      multiple
      label="Drag & drop images here"
      accept="image/jpg,image/jpeg,image/png,image/gif"
      outlined
    />
    <div class="img-preview-container">
      <v-hover
        v-slot:default="{ hover }"
        v-for="(img, i) in imgs"
        :key="img.url"
      >
        <v-img
          :src="imageWidthResize(img.url)"
          max-height="150px"
          max-width="150px"
          width="auto"
          contain
          class="img-preview"
          :class="{ 'on-hover': hover }"
        >
          <v-row class="fill-height" justify="center">
            <div class="align-self-center">
              <v-btn
                :class="{ 'show-btns': hover && i !== 0 }"
                color="rgba(0,0,0,0)"
                icon
                @click="moveImgBackwards(i)"
              >
                <v-icon
                  :class="{ 'show-btns': hover && i !== 0 }"
                  color="rgba(0,0,0,0)"
                >
                  mdi-arrow-expand-left
                </v-icon>
              </v-btn>
              <v-btn
                :class="{ 'show-btns': hover }"
                color="rgba(0,0,0,0)"
                icon
                @click="removeImg(i)"
              >
                <v-icon :class="{ 'show-btns': hover }" color="rgba(0,0,0,0)">
                  mdi-delete-outline
                </v-icon>
              </v-btn>
              <v-btn
                :class="{ 'show-btns': hover }"
                color="rgba(0,0,0,0)"
                icon
                :href="img.url"
                target="_blank"
              >
                <v-icon :class="{ 'show-btns': hover }" color="rgba(0,0,0,0)">
                  mdi-fullscreen
                </v-icon>
              </v-btn>
              <v-btn
                :class="{
                  'show-btns': hover && i !== imgs.length - 1,
                }"
                color="rgba(0,0,0,0)"
                icon
                @click="moveImgForward(i)"
              >
                <v-icon
                  :class="{
                    'show-btns': hover && i !== imgs.length - 1,
                  }"
                  color="rgba(0,0,0,0)"
                >
                  mdi-arrow-expand-right
                </v-icon>
              </v-btn>
            </div>
          </v-row>
        </v-img>
      </v-hover>
      <div v-if="uploadImgLoading" class="loading-img">
        <v-progress-circular
          style="margin: 1rem; float: right"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import uploadImage from "services/image-uploader";
import optimizeImageSize from "services/image-compressor";
export default {
  props: {
    imgProps: Array,
  },
  data() {
    return {
      imgs: [],
      imgInput: null,
      uploadImgLoading: false,
      uploadProgress: {
        currentPercentage: 0,
        currentImage: null,
      },
    };
  },
  watch: {
    imgProps: {
      handler(newImgProps) {
        if (newImgProps) this.imgs = newImgProps;
      },
      immediate: true,
    },
  },
  methods: {
    imageWidthResize(img) {
      const splitImageUrl = new URL(img);
      if (splitImageUrl.protocol.includes("blob")) {
        return img;
      } else {
        return `https://assets.ucars.sg/image/upload/c_fit,f_auto,w_200/v1/s3${splitImageUrl.pathname}`;
      }
    },
    dropImgs(e) {
      this.addImgs(Array.from(e.dataTransfer.files));
    },
    async addImgs(files) {
      if (files && files.length > 0) {
        this.uploadImgLoading = true;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileOptimized = await optimizeImageSize(file);
          this.imgs.push(fileOptimized);
        }
        this.imgInput = null;
        this.$emit("input", this.imgs);
        this.uploadImgLoading = false;
      }
    },
    removeImg(index) {
      this.imgs.splice(index, 1);
    },
    moveImgBackwards(index) {
      const tempFile = this.imgs[index - 1];
      this.$set(this.imgs, index - 1, this.imgs[index]);
      this.$set(this.imgs, index, tempFile);
    },
    moveImgForward(index) {
      const tempFile = this.imgs[index + 1];
      this.$set(this.imgs, index + 1, this.imgs[index]);
      this.$set(this.imgs, index, tempFile);
    },
    async upload(isObscure = false) {
      const self = this;
      try {
        const result = [];
        let img, url;
        for (let i = 0; i < self.imgs.length; i++) {
          img = self.imgs[i];
          self.uploadProgress.currentImage = i + 1;
          if (img.file) {
            url = (
              await uploadImage(
                img.file,
                {
                  onUploadProgress: event => {
                    self.uploadProgress.currentPercentage = Math.round(
                      (event.loaded / event.total) * 100
                    );
                  },
                },
                isObscure
              )
            ).data.data;
            result.push(url);
          } else {
            result.push(img.url);
          }
          self.uploadProgress.currentImage = 0;
        }
        return result;
      } catch (e) {
        console.error(e);
        self.$emit("error");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.loading-img
  display: flex
  justify-content: center
  align-items: center
  width: 80px
.show-btns
  color: rgba(0, 0, 0, 1) !important

::v-deep .on-hover
  .v-image__image
    opacity: 0.3

.img-preview-container
  display: flex
  flex-wrap: wrap
  margin-bottom: 20px
</style>
