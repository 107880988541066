import postAxios from "services/axios-post";
import getAxios from "services/axios-get";
import uploadSignedImage from "../../../services/aws-upload-image";
import createWatermark from "../../../services/create-watermark";

import ImgGroupInput from "@/components/ImgGroupInput";

export default {
  components: {
    ImgGroupInput,
  },
  props: {
    coeCatList: {
      type: Array,
    },
    vesCategoryList: {
      type: Array,
    },
    carBrandList: {
      type: Array,
    },
    carTypeList: {
      type: Array,
    },
    carFuelTypeList: {
      type: Array,
    },
  },
  data() {
    return {
      message: null,
      type: null,
      radioWatermark: "none",
      loadingUpd: false,
      selectionChoice: [
        {
          name: "Yes",
          value: "Yes",
        },
        {
          name: "No",
          value: "No",
        },
      ],
      selectionChoiceAdPi: [
        {
          name: "AD",
          value: "AD",
        },
        {
          name: "PI",
          value: "PI",
        },
        {
          name: "Both",
          value: "Both",
        },
      ],
      addNewModel: {
        carBrandId: "",
        modelName: "",
        subModelName: "",
        modelYear: 0,
        faceliftedYear: 0,
        modelEndYear: 0,
        carTypeId: "",
        pastCarModel: "",
        adPiModel: "",
        coeCategoryId: "",
        description: "",
        modelPros: "",
        modelCons: "",
        carbuyerReview: "",
        displayImgs: [],
        fuelTypeId: "",
        // not used below
        baseName: "",
        carUseId: null,
        defaultImgPath: null,
        doorNum: 0,
        editionName: null,
        efficiency: 0,
        engine: 0,
        estimatedDepreciation: 0,
        estimatedPrice: 0,
        horsePower: 0,
        image360Path: null,
        manuYear: 0,
        modelPicName: null,
        modelPicPath: null,
        name: "",
        roadTax: 0,
        sortCode: 0,
        transmission: "",
        vesCategoryId: null,
        //
      },
      transmissionList: [
        { id: "auto", name: "auto" },
        { id: "manu", name: "manual" },
      ],
      showDialog: false,
    };
  },
  methods: {
    async doAddNewCarModel() {
      const self = this;
      try {
        if (!self.addNewModel.pastCarModel) {
          self.showAlert("error", "Current/Past cannot be empty");
          return;
        } else if (!self.addNewModel.adPiModel) {
          self.showAlert("error", "AD/PI cannot be empty");
          return;
        }
        self.loadingUpd = true;
        self.addNewModel.name = self.addNewModel.modelName;
        self.addNewModel.baseName = self.addNewModel.subModelName;
        const isObscure = this.radioWatermark == "watermark" ? true : false;
        const imgResLists = self.$refs.imgs.imgs;
        let img;
        for (let i = 0; i < imgResLists.length; i++) {
          img = imgResLists[i];
          if (img) {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: isObscure,
              },
              this.token
            );
            if (isObscure) {
              const watermarkedImage = await createWatermark(img.file);
              if (watermarkedImage) {
                await uploadSignedImage(
                  watermarkedImage,
                  uploadUrl.data.waterMarkedUrl
                );
                self.addNewModel.displayImgs.push(
                  uploadUrl.data.waterMarkedName
                );
              }
            } else {
              await uploadSignedImage(img.file, uploadUrl.data.originalUrl);
              self.addNewModel.displayImgs.push(uploadUrl.data.originalName);
            }
          }
        }
        // const imgResList = [...(await self.$refs.imgs.upload())];

        self.addNewModel.defaultImgPath = self.addNewModel.displayImgs.shift();
        // self.addNewModel.displayImgs = imgResList;
        self.addNewModel.modelYear = Number(self.addNewModel.modelYear);
        self.addNewModel.manuYear = Number(self.addNewModel.manuYear);
        self.addNewModel.efficiency = Number(self.addNewModel.efficiency);
        self.addNewModel.doorNum = Number(self.addNewModel.doorNum);
        self.addNewModel.engine = Number(self.addNewModel.engine);
        self.addNewModel.estimatedPrice = Number(
          self.addNewModel.estimatedPrice
        );
        self.addNewModel.estimatedDepreciation = Number(
          self.addNewModel.estimatedDepreciation
        );
        self.addNewModel.horsePower = Number(self.addNewModel.horsePower);
        self.addNewModel.roadTax = Number(self.addNewModel.roadTax);
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/addCarModel`,
          self.addNewModel,
          self.token
        )
          .then(res => {
            if (res.data) {
              if (res.status == 201) {
                self.showAlert("error", res.data.message);
                self.$emit("error", res.data.message);
                return;
              }
              self.showDialog = false;
              self.$emit("add");
            }
            self.loadingUpd = false;
          })
          .catch(e => {
            self.showAlert("error", e);
          })
          .finally(() => (this.loadingUpd = false));
        self.loadingUpd = false;
      } catch (e) {
        self.showAlert("error", e);
        self.$emit("error", e);
        self.loadingUpd = false;
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
