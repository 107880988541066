var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"drop":function($event){$event.preventDefault();return _vm.dropImgs($event)},"dragover":function($event){$event.preventDefault();}}},[_c('v-dialog',{attrs:{"value":_vm.uploadProgress.currentImage > 0,"persistent":"","max-width":"600"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Uploading Image "+_vm._s(_vm.uploadProgress.currentImage)+"/"+_vm._s(_vm.imgs.length)+" ")]),_c('v-progress-linear',{attrs:{"value":_vm.uploadProgress.currentPercentage,"height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(value)+"%")])]}}])})],1)],1),_c('v-file-input',{attrs:{"clearable":"","multiple":"","label":"Drag & drop images here","accept":"image/jpg,image/jpeg,image/png,image/gif","outlined":""},on:{"change":_vm.addImgs},model:{value:(_vm.imgInput),callback:function ($$v) {_vm.imgInput=$$v},expression:"imgInput"}}),_c('div',{staticClass:"img-preview-container"},[_vm._l((_vm.imgs),function(img,i){return _c('v-hover',{key:img.url,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"img-preview",class:{ 'on-hover': hover },attrs:{"src":_vm.imageWidthResize(img.url),"max-height":"150px","max-width":"150px","width":"auto","contain":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center"}},[_c('div',{staticClass:"align-self-center"},[_c('v-btn',{class:{ 'show-btns': hover && i !== 0 },attrs:{"color":"rgba(0,0,0,0)","icon":""},on:{"click":function($event){return _vm.moveImgBackwards(i)}}},[_c('v-icon',{class:{ 'show-btns': hover && i !== 0 },attrs:{"color":"rgba(0,0,0,0)"}},[_vm._v(" mdi-arrow-expand-left ")])],1),_c('v-btn',{class:{ 'show-btns': hover },attrs:{"color":"rgba(0,0,0,0)","icon":""},on:{"click":function($event){return _vm.removeImg(i)}}},[_c('v-icon',{class:{ 'show-btns': hover },attrs:{"color":"rgba(0,0,0,0)"}},[_vm._v(" mdi-delete-outline ")])],1),_c('v-btn',{class:{ 'show-btns': hover },attrs:{"color":"rgba(0,0,0,0)","icon":"","href":img.url,"target":"_blank"}},[_c('v-icon',{class:{ 'show-btns': hover },attrs:{"color":"rgba(0,0,0,0)"}},[_vm._v(" mdi-fullscreen ")])],1),_c('v-btn',{class:{
                'show-btns': hover && i !== _vm.imgs.length - 1,
              },attrs:{"color":"rgba(0,0,0,0)","icon":""},on:{"click":function($event){return _vm.moveImgForward(i)}}},[_c('v-icon',{class:{
                  'show-btns': hover && i !== _vm.imgs.length - 1,
                },attrs:{"color":"rgba(0,0,0,0)"}},[_vm._v(" mdi-arrow-expand-right ")])],1)],1)])],1)]}}],null,true)})}),(_vm.uploadImgLoading)?_c('div',{staticClass:"loading-img"},[_c('v-progress-circular',{staticStyle:{"margin":"1rem","float":"right"},attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }